<template>
  <div class="rank">
    <div class="actionBar">
      <a-input-search
        size="large"
        v-model="listParams.keyword"
        placeholder="请输入关键词搜索"
        enter-button="查询"
        style="width: 350px"
        allowClear
        @search="onSearch"
      />
      <a-button
        icon="plus"
        type="primary"
        @click="showModal"
        size="large"
        v-if="handLists.top?handLists.top.indexOf('add') != -1:false"
      >
        添加职称</a-button
      >
    </div>

    <a-modal
      :title="modalTitle"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确定"
      cancelText="取消"
    >
      <a-form
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
        :from="addFromData"
      >
        <a-form-item label="角色名称">
          <a-input v-model="addFromData.name" />
        </a-form-item>

        <a-form-item label="角色名称">
          <a-radio-group v-model="addFromData.status">
            <a-radio :value="1">
              启用
            </a-radio>
            <a-radio :value="0">
              禁用
            </a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-modal>

    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      :pagination="{
        total: count,
        current: listParams.page,
        pageSize: listParams.limit,
        showTotal: (total) => `共 ${total} 条`,
      }"
      @change="pageChange"
    >
      <span slot="action" slot-scope="text, record">
        <span v-if="handLists.right?handLists.right.indexOf('edit')!=-1:false">
          <FontIcon
            @click.native="editMenu(record)"
            title="修改"
            type="icon-xiugai"
            size="22"
          ></FontIcon>
          <a-divider type="vertical" />
        </span>

        <a-popconfirm
          title="确定删除？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="confirm(record)"
          v-if="handLists.right?handLists.right.indexOf('del') != -1:false"
        >
          <FontIcon title="删除" type="icon-lajitong" size="22"></FontIcon>
        </a-popconfirm>
      </span>
    </a-table>
  </div>
</template>

<script>
import {
  professionalIndex,
  professionalDataSave,
  professionalDelete,
} from "@/apis/index";

const columns = [
  {
    title: "职称ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
    width: 120,
    align: "center",
  },
  {
    title: "职称名称",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
    customRender: (text, record) => {
      if (text == 1) {
        return "启用";
      } else if (text == 0) {
        return "禁用";
      }
    },
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  data() {
    return {
      columns,
      data: [],
      visible: false,
      confirmLoading: false,
      modalTitle: "",
      addFromData: {},
      listParams: {
        page: 1,
        limit: 10,
        keyword: "",
      },
      count: 0,
      handLists: this.$store.state.menuHandleList,
    };
  },
  computed: {
    handList() {
      return this.$store.state.menuHandleList;
    },
  },
  watch: {
    handList(val) {
      this.handLists = val;
    },
  },
  created() {
    this.getProfessionalIndex();
  },
  methods: {
    getProfessionalIndex() {
      professionalIndex(this.listParams).then((res) => {
        if (res.data.list.length == 0 && this.listParams.page > 1) {
          this.listParams.page--;
          this.getProfessionalIndex();
        } else {
          this.data = res.data.list;
          this.count = res.data.count;
        }
      });
    },
    showModal() {
      this.modalTitle = "新增职称";
      (this.addFromData = {
        id: "",
        name: "",
        status: 1,
      }),
        (this.visible = true);
    },
    handleOk(e) {
      this.confirmLoading = true;
      professionalDataSave(this.addFromData).then((res) => {
        if (res.code == 1) {
          this.getProfessionalIndex();
          this.visible = false;
        }
        this.confirmLoading = false;
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
    editMenu(record) {
      this.modalTitle = "修改职称信息";
      this.addFromData = { ...record };
      this.visible = true;
    },
    confirm(rec) {
      professionalDelete({ id: rec.id }).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功");
          this.getProfessionalIndex();
        }
      });
    },
    pageChange(a) {
      this.listParams.page = a.current;
      this.getProfessionalIndex();
    },
    onSearch(value) {
      this.listParams.page = 1;
      this.getProfessionalIndex();
    },
  },
};
</script>
<style lang="less" scoped>
.rank {
  .actionBar {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
  }
}
</style>
